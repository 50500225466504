.faqSection{
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #EFE3D7;
    display: flex;
    margin: auto;
    max-width: 1280px;
}

.faqTitleColumn{
    padding-left: 50px;
    margin-top: 0px!important;
    width: 50%;
}

.faqTitleColumn h2{
    width: 80%;
}

.accordion{
    /* margin-bottom: 20px!important; */
    box-shadow: none!important;
    border-bottom: 0.3px solid rgb(116, 116, 116)!important;
    border-top: none!important;
    background-color: #EFE3D7!important;
    /* border:none!important */
    border-bottom: none!important;
}

.faqQuestionsColumn{
    padding-top: 10px;
    width: 700px;
    padding-right: 20px;
}

.faqQuestionsColumn h4{
    margin-bottom: 20px;
    padding-top:20px;
    margin-top: 0px;
}

.faqAnswer{
    margin-top: 0px;
}

@media only screen and (max-width: 600px) {
    .faqSection{
        display: block;
    }

    .faqTitleColumn{
        padding-left: 0px;
        width: 100%;
    }

    .faqTitleColumn h2{
        text-align: center;
        width: 100%;
    }

    .faqQuestionsColumn{

        width: 100%;
        padding-right: 0px;
    }
}