.kontaktFormAndTextContainer {
    background-image:
    linear-gradient(to bottom,rgba(240, 133, 106, 0.15), rgba(214, 184, 154, 0.25),rgba(217, 217, 217, 0));
}

.kontaktFormAndText {
    padding-top: 60px;
    padding-bottom: 90px;
    margin: auto;
    max-width: 1280px;
    display: flex;
    gap: 40px;
}

.contactFormFullForm {
    width: 50%;
}
.kontaktFormAndTextText{
    width: 50%;
    padding-right: 50px;
}

.kontaktFormAndTextForm {
    display: flex;
    width: 50%;
}

.kontaktUppgifterMedBild{
    display: flex;
    gap: 30px;
}

.kontaktUppgifterMedBild h4{
    margin-top: 1px;
}

.specialistDiv{
    margin-top: 60px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-top: 10px;
    background-color: rgba(255, 255, 255, 0.4) ;
}

/* 1200px start*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .kontaktFormAndText{
        max-width: 1080px;
    }
}
/* 1200px end*/

/* 601-768px start*/
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .kontaktFormAndText {
        display: block;
    }
    .kontaktFormAndTextText{
        padding-left: 20px;
        width: 90%;
    }
    .contactFormInput{
        width: 700px;
    }
}
/* 601-768px end*/

@media only screen and (max-width: 600px) {
    .kontaktFormAndTextContainer {
        /* padding-left: 20px; */
    }

    .kontaktFormAndText{
        padding-bottom: 0px;
    }

    .specialistDiv{
        padding: 0px;
        height: 510px;
        padding-top: 30px;
    }
    .titleSpecialistDiv{
        text-align: center;
    }

    .kontaktUppgifterMedBild{
        padding-bottom: 0px;
        text-align: center;
        display: block;
    }

    .staffPicContactDetailsKontaktPage{
        padding-top: 20px;
    }

    .kontaktFormAndText {
        border-radius: 10px;
        padding-right: 20px;
        padding-left: 20px;
        display: block;
    }

    .kontaktFormAndTextText{
        width: 100%;
    }

    .contactFormFullForm {
        width: 100%;
    }

    .kontaktFormAndTextForm {
        display: flex;
        width: 100%!important;
    }

    .contactText{
        padding-bottom: 20px;
    }
}