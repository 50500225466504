/* @media only screen and (min-width: 1400px)  {
    .container{
        max-width: 1280px;
        max-height: 410px;
    }
} */


.container{
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 410px;
    background-color: 
    rgba(214, 184, 154, 0.4);
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    text-align: center;
    padding-bottom: 90px;
}

.headerTitle{
    margin-bottom:20px;
}

.sectionText{
    margin:auto;
    margin-bottom:30px;
    max-width: 780px;
}

/* 601-768px start*/
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .sectionText{
        margin-bottom:30px;
        width: 90%;
    }
    .headerTitle{
        padding-left: 10px;
        padding-right: 10px;
    }
    .container{
        margin-top: 0px;
    }
}
/* 601-768px end*/

@media only screen and (max-width: 600px) {
    .sectionText{
        margin:auto;
        margin-bottom:30px;
        max-width: 90%;
    }
    .container{
        background-position: bottom;
    }
}