h1,
h2 {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    line-height: 59px;
    font-weight: 600;

}

h3 {
    font-family: 'Inter', sans-serif;
    font-size: 34px;
    line-height: 41px;
    font-weight: 600;
}

h4 {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
}

h5 {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #F0856A;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.infoSectionsHome {
    padding-top: 120px;
    padding-bottom: 60px;
    background-image:
        linear-gradient(to bottom, rgba(217, 217, 217, 0), rgba(214, 184, 154, 0.25), rgba(240, 133, 106, 0.15));

}

.infoSectionsVaraTjanster {
    padding-top: 120px;
    padding-bottom: 60px;
    background-image:
        linear-gradient(to bottom, rgba(217, 217, 217, 0), rgba(214, 184, 154, 0.25), rgba(240, 133, 106, 0.15));

}

.infoSectionsOmOss {
    background-image:
        linear-gradient(to bottom, rgba(214, 184, 154, 0.25), rgba(240, 133, 106, 0.15), rgba(217, 217, 217, 0));

}


p {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
}

.CtaSectionWithBg {
    color: #fff;
    /* max-width: 1170px; */
    margin-left: auto;
    margin-right: auto;
}

.ctaBtnOrange {
    font-family: 'Inter', sans-serif;
    background-color: #F0856A;
    color: #fff;
    border: 1px solid #F0856A;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 24px;
    display: inline-block;
    transition: all 0.3s ease;
}

.ctaBtnOrange:hover {
    cursor: pointer;
    scale: 1.05;
    background-color: #ee6846;
    border: 1px solid #F0856A;
}

.separatorOmOss{
    margin-top: 60px;
}

.mailMattias{
    margin-top: -20px;
    margin-bottom: -15px;
}

/* 601-768px start*/
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .infoSectionsHome {
        padding-top: 0px;
        padding-bottom: 60px;
    }
    .infoSectionsVaraTjanster {
        padding-top:480px;
    }
}

/* 601-768px end*/

/*MOBILE STYLES START*/
@media only screen and (max-width: 600px) {

    html,
    body {
        overflow-x: hidden;
    }


    h1,
    h2 {
        font-family: 'Inter', sans-serif;
        font-size: 32px;
        line-height: 48px;
        font-weight: 600;

    }

    h3 {
        font-family: 'Inter', sans-serif;
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
    }

    h4 {
        font-family: 'Inter', sans-serif;
        font-size: 22px;
        line-height: 29px;
        font-weight: 600;
    }

    h5 {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 18px;
        color: #F0856A;
        font-weight: 600;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }

    p {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }

    .infoSectionsHome {
        padding-top: 0px;
        padding-bottom: 60px;
        background-image:
            linear-gradient(to bottom, rgba(217, 217, 217, 0), rgba(214, 184, 154, 0.25), rgba(240, 133, 106, 0.15));

    }

    .infoSectionsVaraTjanster {
        padding-top: 440px;
        padding-bottom: 60px;
        background-image:
            linear-gradient(to bottom, rgba(217, 217, 217, 0), rgba(214, 184, 154, 0.25), rgba(240, 133, 106, 0.15));

    }
}