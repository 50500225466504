.container {
    max-width: 1280px;
    /* text-align: center; */
    margin: auto;
}

.gridContainer {
    display: flex;
}

.gridText{
    margin-top: -20px;
}

.headerAndText {
    padding-top: 40px;
    text-align: center;
    padding-bottom: 40px;
}

/* 1200px start*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .container{
        max-width: 1080px;
    }
}
/* 1200px end*/

/* 601-768px start*/
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .container {
        width: 90%;
        padding-top: 440px;
        /* max-width: 100%; */
    }
    .gridBoxMiddle, .gridBoxRight , .gridBoxLeft{
        display: contents;
        /* margin-left: auto!important; */
    }
    .gridContainer {
        justify-content: center;
        /* margin-left: auto!important;
        margin-right: auto; */
        /* padding-left:50px; */
    }

    .gridText{
        font-size: 14px;
        text-align: center;
        /* margin-right: auto; */
        padding-left: 40px;
        padding-right: 10px;
        margin-top: -5px;
    }
    .iconImg{
        width: 50px;
        height: 50px;
        padding-top: 20px;
        margin-right: 10px;
    }
}
/* 601-768px end*/

@media only screen and (max-width: 600px) {
    .container {
        max-width: 600px;
        padding-top: 440px;
        /* max-width: 100%; */
    }
    .gridBoxMiddle, .gridBoxRight , .gridBoxLeft{
        display: contents;
        /* margin-left: auto!important; */
    }
    .gridContainer {
        justify-content: center;
        /* margin-left: auto!important;
        margin-right: auto; */
        /* padding-left:50px; */
    }

    .gridText{
        font-size: 14px;
        text-align: center;
        /* margin-right: auto; */
        padding-left: 40px;
        padding-right: 10px;
        margin-top: -5px;
    }
    .iconImg{
        width: 50px;
        height: 50px;
        padding-top: 20px;
        margin-right: 10px;
    }
}
