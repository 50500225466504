.headerTitleTestimonial{
    text-align: center;
}

.sectionTestimonial{
    padding-top: 60px;
    padding-bottom: 60px;
}

.mySwiper{
    padding-top: 10px!important;
    padding-bottom: 30px!important;
}

.sliderDiv{
    margin: auto;
    padding-top: 1px;
    width: 740px;
    height: 410px;
    background-color: #fff;
    border-radius: 10px;
    border:inset 1px solid #ccc;
    box-shadow: 0 0 10px #ccc;
}
.containerTestimonial{
    gap: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.contentTestimonial{
    width: 520px;
    margin-top: 80px;
    margin-left: 70px;
}

.textAndPic{
    display: flex;
    gap: 20px;
}

.picTestimonial{
    margin-top: 10px;
    width: 80px;
    height: 80px;
}

.titleTestimonial{
    margin-top: -15px;
}

/* 1400px start*/
@media only screen and (min-width: 1400px)  {
    .sliderDiv{
        max-width: 620px;
    }
    .contentTestimonial{
        padding-top: 10px;
        margin-top:20px;
        width: 450px;
    }
}
/* 1400px end*/

/* 1200px start*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .sliderDiv{
        max-width: 520px;
    }
    .contentTestimonial{
        padding-top: 10px;
        margin-top:20px;
        width: 350px;
    }
}
/* 1200px end*/

/* 601-768px start*/
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .sliderDiv{
        margin: auto;
        padding-top: 1px;
        width: 90%;
        height: 410px;
        background-color: #fff;
        border-radius: 10px;
        border:inset 1px solid #ccc;
        box-shadow: 0 0 10px #ccc;
    }
}
/* 601-768px end*/

@media only screen and (max-width: 600px) {
    .sliderDiv{
        margin: auto;
        padding-top: 1px;
        width: 90%;
        height: 410px;
        background-color: #fff;
        border-radius: 10px;
        border:inset 1px solid #ccc;
        box-shadow: 0 0 10px #ccc;
    }
    .contentTestimonial{
        margin-left:20px!important;
        margin-right: 20px!important;
        width: 90%;
        margin-top: 80px;
        margin-left: 70px;
    }
}