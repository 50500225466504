.containerSectionSamarbetspartner{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 40px;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

/* .sliderDivSamarbetspartner{
    margin: auto;
    max-width: 1280px;
} */

.containerSamarbetspartner{
    padding-top: 30px;
}

/* .partnersImg{
    display: flex;
    gap: 90px;
} */