.containerInfoSection{
    padding-top: 60px;
    padding-bottom: 60px;
    max-width: 1280px;
    display: flex!important;
    margin:auto
}

.bigTitle{
    margin:0px;
}

.textLeft{
    margin-top:-20px;
    height: 310px;
    margin-right: 20px;
    max-width: 620px;
}
.textRight{
    margin-top:-20px;
    height: 310px;
    margin-left: 20px;
    max-width: 620px;
} 


.imgRight{
    max-width: 620px!important;
    margin-left: 20px;
}

.imgRight img{
    height: 415px;
    max-width: 620px!important;
}

.imgLeft img{
    height: 415px;
    max-width: 620px!important;
}

.imgLeft{
    max-width: 620px!important;
    margin-right: 20px;
}

/* 1200px start*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .containerInfoSection{
        max-width: 1080px;
    }
}
/* 1200px end*/


/* 601-768px start */
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .containerInfoSection{
        padding-top:0px;
        width: 90%;
        display: block!important;
    }
    .imgInfoSection{
        max-width: 100%;
        margin: auto;
    }
    .imgRight img{
        height: 580px;
        width: auto!important;
    }
    
    .imgLeft img{
        height: 580px;
        width: 768px!important;
    }

    .imgInfoSection{
        height: 580px;
        width: 768px!important;
    }
}
    
/* 601-768px end */

@media only screen and (max-width: 600px) {
    .containerInfoSection{
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        padding-bottom: 30px;
        display: block!important;
        margin:auto
    }
    .imgInfoSection{
        max-width: 100%;
        margin: auto;
    }

    .textSection{
        padding-bottom:20px;
    }
}