@media only screen and (min-width: 1400px)  {
    .containerHeader {
        max-width: 1280px;
        /* width: 100%; */
        height: 590px;
    }
    
    .headerContent{
        margin: auto;
        color: #fff;
        background: linear-gradient(to right, #212226,  #212226d0, #21222634);
        position: absolute;
        width: 100%;
        height: 590px;
    }
    
    .headerContent h1{
        margin:0px;
        max-width: 600px;
        padding-top: 150px;
    }
    
    .headerContent p{
        margin-bottom: 30px;
        max-width: 600px;
    }
    
    .headerText{
        margin: auto;
        max-width: 1280px;
    }
    
    .overlay{
        position: absolute;
        /* top: 0;
        left: 0; */
        width: 100%;
        height: 590px;
    }
    
    video{
        width: 100%;
        height: 590px;
        object-fit: cover;
    }
}



@media (min-width: 1200px) and (max-width : 1399px) {

.containerHeader {
    max-width: 1080px;
    /* width: 100%; */
    height: 590px;
}
.headerContent{
    margin: auto;
    color: #fff;
    background: linear-gradient(to right, #212226,  #212226b0, #21222634);
    position: absolute;
    width: 100%;
    height: 590px;
}

.headerContent h1{
    margin:0px;
    max-width: 600px;
    padding-top: 150px;
}

.headerContent p{
    margin-bottom: 30px;
    max-width: 600px;
}

.headerText{
    margin: auto;
    max-width: 1080px;
}

.overlay{
    position: absolute;
    /* top: 0;
    left: 0; */
    width: 100%;
    height: 590px;
}

video{
    width: 100%;
    height: 590px;
    object-fit: cover;
}
}

/* 601-768px start*/
@media only screen and (min-width: 601px) and (max-width: 768px) {
    .containerHeader {
        width: 100%;
    }
    .headerContent{
        margin: auto;
        color: #fff;
        background: linear-gradient(to right, #212226,  #212226b0, #21222634);
        position: absolute;
        /* width: 380px; */
        height: 440px;
        padding-right: 20px;
        padding-left: 20px;
    }
    
    .headerContent h1{
        /* margin:0px;
        max-width: 600px; */
        padding-top: 60px;
    }
    
    .headerContent p{
        margin-bottom: 30px;
        max-width: 600px;
    }
    
    .overlay{
        position: absolute;
        /* top: 0;
        left: 0; */
        width: 100%;
        height: 440px;
    }
    
    video{
        width: 100%;
        height: 440px;
        object-fit: cover;
    }
}
/* 601-768px end*/

@media only screen and (max-width: 600px) {

    .containerHeader {
        width: 100%;
    }
    .headerContent{
        margin: auto;
        color: #fff;
        background: linear-gradient(to right, #212226,  #212226b0, #21222634);
        position: absolute;
        /* width: 380px; */
        height: 440px;
        padding-right: 20px;
        padding-left: 20px;
    }
    
    .headerContent h1{
        /* margin:0px;
        max-width: 600px; */
        padding-top: 60px;
    }
    
    .headerContent p{
        margin-bottom: 30px;
        max-width: 600px;
    }
    
    .overlay{
        position: absolute;
        /* top: 0;
        left: 0; */
        width: 100%;
        height: 440px;
    }
    
    video{
        width: 100%;
        height: 440px;
        object-fit: cover;
        display: block;
    }
}
