.calcContainer input {
  width: 180px;
  padding-top: 22px;
  padding-bottom: 22px;
}


.columnBesparingCO2TextMob{
  padding-top:20px
}

.arligaBesparingText{
  padding-top: 20px;
  border-top: 1px solid #F0856A;
}

.fullCalculatorMob{
  padding-top:60px;
}

.besparingTotalText {
  font: 34px Inter, sans-serif;
  font-weight: 600;
  color: #F0856A;
}

.besparingTextMob{
  padding-left: 20px;
}

.besparingTotalCO2Text {
  font: 34px Inter, sans-serif;
  font-weight: 600;
  color: #F0856A;
}

.columnBesparingTextWithBtnMob{
  width: 90%;
  text-align: center;
   margin-left: auto;
  margin-right: auto; 
  margin-top: 30px;
  border-top:1px solid #F0856A;
}

.iconTrucksDesktop{
  display: block;
  margin-top: -130px;
  padding-bottom: 0px;
  padding-left: 25px;
}

.titelIcon{
 padding-left: 45px;
}

.pageTitleAndText {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  padding-bottom: 180px;
}


.showCalcSum{
  margin-bottom: 60px;
  padding-bottom: 40px;
  padding-top: 60px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1060px;
  background-color: #F2E9DF;
}

.besparingTextContainer{
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
}

.besparingTextContent{
  display: flex;
}

.columnBesparingText{
  width: 33.3%;
}

.columnBesparingTextWithBtn{
  padding-top: 25px;
  padding-left: 60px ;
  border-left: 0.5px solid #F0856A;
}

.inputFieldsCalc{
  display: flex!important;
}

.disclamerText{
  padding-top: 20px;
  padding-bottom:20px;
  margin-left: auto;
  margin-right: auto;
  width: 1080px;
}

.disclamerText p{
  font-size: 14px;
  color: #666666;
}

.rowChoise {
  width: 1060px;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  /* justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0; */
}

.buttons {
  padding-top: 30px;
  display: flex;
  width: 1060px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 90px;
}

.hideShowCalcRefined {
  display: none;
}

.hideCalcSum {
  display: none;
}

.leftBtn {
  width: 50%;
}

.rightBtn {
  padding-right: 60px;
  text-align: right;
  width: 50%;
}

.calcBtn {
  width: 1060px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 180px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-right: 40px !important;
}

.css-fvipm8 {
  width: 210px!important;
    height: 50px!important;
}

.numAndTitle {
  width: 35%;
  gap: 20px;
  display: flex;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 180px !important;
  margin-right: 40px;
}

.circle {
  margin-top: 5px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 10px;
  background: #fff;
  border: 3px solid #F0856A;
  text-align: center;
  font: 24px Inter, sans-serif;
}

.refinedCalcButtonMob{
  color: #F0856A;
  border : 1px solid #F0856A;
  border-color: #F0856A;
  border-radius: 10px;
  background-color: #fff;
  padding:10px 60px; 
}


.refinedCalcButton{
  color: #F0856A;
  border : 1px solid #F0856A;
  border-color: #F0856A;
  border-radius: 5px;
  background-color: #fff;
  padding:10px 60px; }


@media only screen and (min-width: 769px) and (max-width: 1400px){
  .pageTitleAndText{
    max-width: 90%;
    padding-left: 20px!important;
  }
}

/* 601-768px start*/
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .pageTitleAndTextMob{
    max-width: 80%;
    margin-left: 20px;
  }

  .numAndTitleMob {
    padding-top: 40px;
    text-align: center;
  }

  .numAndTitleMob h4{
    margin-top:5px;
  }

  .calcbtnMob{
    padding-top:60px;
    text-align: center;
    width: 100%;
    margin-left: auto;
  }

  .refinedCalcButtonplaceholderMob{
    padding-top: 20px;
    text-align: center;
  }

  .inputFieldsCalcMob {
    padding-left: 20px;
  }

  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 95%;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-left: auto;
    margin-right: auto;
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 95% !important;
    margin-right: auto;
    margin-left: auto;
  }

  .css-i44wyl {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .css-fvipm8 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .circle {
    margin-left: auto;
    margin-right: auto;
  }

  .showCalcSum{
    padding-top: 20px;
    margin-right: auto;
    margin-left: 30%;
  }

  .disclamerTextMob{
    font-size: 14px;
    color: #666666;
    margin-top:60px;
    border-top: 1px solid #F0856A;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
/* 601-768px end*/

/*Mobile starts here */
@media only screen and (max-width: 600px) {
  .pageTitleAndTextMob{
    max-width: 80%;
    margin-left: 20px;
  }

  .disclamerTextMob p{
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    font-size: 14px;
    color: #666666;
  }

  .numAndTitleMob {
    padding-top: 40px;
    text-align: center;
  }

  .numAndTitleMob h4{
    margin-top:5px;
  }

  .calcbtnMob{
    padding-top:60px;
    max-width: 90%;
    margin-left: auto;
  }

  .refinedCalcButtonplaceholderMob{
    padding-top: 20px;
    text-align: center;
  }


  .inputFieldsCalcMob {
    padding-left: 20px;
  }

  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 90%;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-left: auto;
    margin-right: auto;
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 90% !important;
    margin-right: auto;
    margin-left: auto;
  }

  .css-i44wyl {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .css-fvipm8 {
    width: 90%!important;
    margin-left: auto;
    margin-right: auto;
  }

  .circle {
    margin-left: auto;
    margin-right: auto;
  }

  .showCalcSum{
    margin-top: 60px;
    padding-top: 20px;
    max-width: 90%;
    /* margin-left: auto; */
  }

  .disclamerTextMob{
    margin-top:60px;
    border-top: 1px solid #F0856A;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}