.staffPic{
    display: flex;
    margin: auto;
    justify-content: center;
    gap:40px;
}

.mailMattias{
    margin-top: -20px;
    margin-bottom: -15px;
}

.staffPicContactDetails{
    margin-top: auto;
    margin-bottom: auto;
}

.headerStaffpic{
    padding-top: 30px;
    text-align: center;
}


/* 1200px start*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .headerStaffpic{
        padding-top: 90px;
    }
}
/* 1200px end*/

/* MOBILE SIZE START */

@media only screen and (max-width: 600px) {
    .staffPic{
        text-align: center;
        display: block;
        margin: auto;
        justify-content: center;
        gap:40px;
    }

    .staffPic img{
        width: 180px;
        padding-left: 10px;
        padding-right: 10px;
        height: auto;
    }

    .headerStaffpic{
        padding-left:10px;
        padding-right:10px;
    }
}

/* MOBILE SIZE END */