.container{
    background-color: #fff;
    position: sticky;
    top: 0px;
    z-index: 99;
}


.navContainer{
    padding-top: 30px;
    padding-bottom:20px;
    width: 100%;
    background-color: #fff;
    z-index: 99;
}

.navContainerActive{
    padding-top: 30px;
    padding-bottom:20px;
    box-shadow: rgba(16, 24, 40, 0.05) 0px 4px 2px 0px;
    width: 100%;
    background-color: #fff;
}

.navbar {
    margin: auto;
    max-width: 1280px;
    background-color: #fff;
    display: flex;
}

.logoImg{
    width: auto;
}

.logoContainer {
    height: 30px;
    width: auto;
    text-align: left;
}

.linkContainer {
    width: 100%;
    text-align: right;
}

.navButton{
    text-decoration: none;
    color: #2D3648;
    border: none;
    margin: 15px;
}

.mobileMenu{
  display: grid;
  padding-bottom: 20px;   
  padding-right: 20px;
}

.navmenuMobile{
    position: sticky!important;
    top: 0px!important;
    z-index: 99!important;
    box-shadow: rgba(16, 24, 40, 0.05) 0px 4px 2px 0px;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 5px;
}

.line1{
    width: 20px;
    height: 2px;
    background-color: #2D3648;
    margin-top: 5px;
    margin-bottom: 5px;
}
.line2{
    width: 20px;
    height: 2px;
    background-color: #2D3648;
    margin-top: 5px;
    margin-bottom: 5px;
}
.line3{
    width: 20px;
    height: 2px;
    background-color: #2D3648;
    margin-top: 5px;
    margin-bottom: 5px;
}

.navButton:hover{
    border-bottom: 2px solid  #F0856A;
}
.navButtonActive{
    text-decoration: none;
    color:  #F0856A;
    border: none;
    margin: 15px;
}

.navCtaButton{
    font-weight: 600;
    text-decoration: none;
    background-color: #F0856A;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 30px;
    margin-bottom: -5px;
    margin-left:15px;
}

/* 1200-1399px start*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .navbar{
        max-width: 1080px;
    }
}
/* 1200-1399 end*/


/* 601-768px start*/
@media only screen and (min-width: 601px) and (max-width: 768px) {

}
/* 601-768px end*/


/*max 600px start*/
@media only screen and (max-width: 600px) {
    .navButton:hover{
        border-bottom: none;
        color: #F0856A;
    }

    .navCtaButton{
        width: 92%;
        text-align: center;
        padding-right: 0px;
    }

    .container{
        background-color: #fff;
        position: fixed;
        top: 0px;
        z-index: 99;
        width: 100%;
    }
}
/*max 600px end*/