.inputField{
    padding-top: 20px;
    width: 550px!important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 40px!important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 550px!important;
}

.inputFieldMessage{
    padding-bottom:20px!important;
    padding-top: 20px;
    width: 550px!important;
}

.css-fvipm8 {
    width: 550px!important;
}

.inputFieldContainer{
    margin-bottom:20px
}

.titelInputField{
    margin-bottom: 5px;
}

.titelInputFieldDropdown{
    padding-top:0px!important;
}

.MuiInputBase-input{
    height: 10px!important;
    border-radius: 5px solid rgba(33, 34, 38, 0.6)!important;
}

.titelInputFieldMessage{
    margin-bottom: 5px;
}

.contactFormInput{
    background-color: #fff;
    padding:40px
}


/* 1200px start*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .inputField{
        padding-top: 20px;
        width: 440px!important;
    }
    
    #selectDropDown{
        width: 440px!important;
    }

    .inputFieldMessage{
        padding-bottom:20px!important;
        padding-top: 20px;
        width: 440px!important;
    }
    
}
/* 1200px end*/

@media only screen and (max-width: 600px) {
    .contactFormInput{
        padding:20px;
        padding-bottom:40px
    }
    .MuiInputBase-input{
        height: 10px!important;
        border-radius: 5px solid rgba(33, 34, 38, 0.6)!important;
    }

    .selectDropDownDiv{
        width: 345px!important;
    }

    .inputField{
        padding-top: 20px;
        width: 100%!important;
    }
    
    .inputFieldContainer{
        width: 100%!important;
    }

    #inputFieldDrowdown {
        width: 400px!important;
    }

    .css-fvipm8 {
        width: 310px!important;
        height: 45px!important;
    }

    .inputFieldMessage{
        width: 100%!important;
    }
}
