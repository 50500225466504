.hurFungerarDetSection{
    padding-top: 40px;
   text-align: center;
   padding-bottom: 40px;
}

@media only screen and (max-width: 600px) {
    /* .ctaSectionVaraTjansterPage{
        margin-top: -40px;
        
    } */
}